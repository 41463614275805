<script setup>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import store from "../../../state/store";

import {reactive} from 'vue'


const data = reactive({
    name: store.getters.getUsername,
    email:store.getters.getEmail
})

console.log(store.getters,data)
</script>

<template>

  <Layout>
    <PageHeader :title="Profile" :items="items" />

    <h2>Profile</h2>


    <div class="mt-4">
    <p class="fs-5"><span class="fw-bold">Name:</span> {{ data.name }}</p>
    <p class="fs-5"><span class="fw-bold">Email:</span> {{ data.email }}</p>
</div>


  </Layout>
  </template>
  